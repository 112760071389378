<template>
  <b-container
    fluid
    class="content-container p-0"
    style="background: #F8F6F7"
  >
    <FormHeader />
    <b-container>
      <b-form @submit.prevent="onSubmit">
        <b-row class="mt-5 mb-5">
          <b-col>
            <b-row class="mb-4">
              <b-col class="text-center h7 p-0">
                What are your goals and expectations?
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <p class="second-headline h9">
                  Tell us about your expectations so we know how we can help you the most.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          cols="1"
          cols-sm="3"
          align-h="center"
          class="text-center ml-5 mr-5 h9 mb-6"
        >
          <b-col
            v-for="goal in goalOptions"
            :key="goal.id"
            class="answer-box text-center ml-2 mr-2 mb-4 pt-1"
            :class="{ active: goals.indexOf(goal.id) > -1 }"
            @click="toggleGoal(goal.id)"
          >
            <p class="m-4">
              {{ goal.label }}
            </p>
          </b-col>
          <b-col
            class="answer-box ml-2 mr-2 mb-4 pt-1"
            :class="{ active: otherActive }"
            @click="otherActive = !otherActive"
          >
            <p class="m-4">
              Other (enter your answer)
            </p>
          </b-col>
          <b-textarea
            v-if="otherActive"
            id="other"
            v-model="other_goal"
            autofocus
            name="other"
            rows="4"
            class="col answer-box ml-2 mr-2 mb-4 pt-1"
            placeholder="Enter text here"
          />
        </b-row>
        <b-row>
          <b-col class="text-center mb-2">
            <b-btn
              v-b-tooltip.hover
              variant="primary"
              class="step2-btn"
              type="submit"
              :disabled="disabled"
              title="Fill all fields to continue"
            >
              Continue
            </b-btn>
          </b-col>
        </b-row>
        <!-- <b-row class="mb-1">
          <b-col class="text-center mb-5">
            <router-link
              :to="`/signup/recruiter/details?token=${$route.query.token}`"
              class="h9"
            >
              Skip for now
              </router-link>
          </b-col>
        </b-row> -->
      </b-form>
    </b-container>
  </b-container>
</template>

<script>
import FormHeader from '@/components/form/FormHeader'
import { vuexMapper } from '@/helpers/vuexMapper'
import { mapActions } from 'vuex'
import axios from '@/helpers/cleanAxios'

export default {
  name: 'SignupStep2',
  components: {
    FormHeader,
  },
  data () {
    return {
      submitting: false,
      goalOptions: [],
    }
  },
  computed: {
    ...vuexMapper({
      fields: ['goals', 'other_goal', 'otherActive'],
      base: 'recruiter',
      nestedObject: 'signupForm',
      mutation: 'UPDATE_STORE',
    }),
    disabled () {
      return this.goals.length === 0 || this.submitting === true
    },
  },
  watch: {
    // When "other" is remove then also remove "other goal" value
    otherActive (newVal) {
      if (!newVal) {
        this.other_goal = ''
      }
    },
  },
  created () {
    axios.get('/public/v2/values/goals')
      .then(({ data }) => {
        this.goalOptions = data
      })
  },
  methods: {
    ...mapActions('recruiter', ['updateSignup']),

    onSubmit () {
      this.submitting = true
      this.updateSignup(this.$route.query.token)
        .then(() => {
          this.$router.push('/signup/recruiter/details?token=' + this.$route.query.token)
        })
        .finally(() => {
          this.submitting = false
        })
    },
    toggleGoal (goal) {
      const index = this.goals.indexOf(goal)
      if (index > -1) {
        const tempGoals = [...this.goals]
        tempGoals.splice(index, 1)
        this.$set(this, 'goals', tempGoals)
      } else {
        const tempGoals = [...this.goals]
        tempGoals.push(goal)
        this.$set(this, 'goals', tempGoals)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-container {
  min-height: 100vh;
  .answer-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px #EDE1DF;
    border: 2px solid transparent;
    border-radius: 12px;
    color: #323031;
    font-size: 16px;
    min-height: 80px;
    &:hover {
      box-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
    }
    &.active {
      border: 2px solid #EE8B7B;
    }
  }
  .step2-btn {
    min-width: 10%;
    height: 41px;
    background: #EE8B7B;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
  }
  .second-headline {
    font-size: 18px;
    line-height: 140%;
  }
}
</style>
